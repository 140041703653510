.ant-drawer-body {
    padding: 0px !important;
}

.ql-snow .ql-editor h5 {
    font-size: 1.4em;
}

.react-resizable {
    position: relative;
    background-clip: padding-box;
}

.react-resizable-handle {
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    cursor: col-resize;
}

.ant-table table {
    font-size: 12px;
}

.ant-table-tbody {
    padding: 4px !important;
}

.ant-table-tbody > tr > td {
    padding: 1px 8px !important;
}

.ant-btn-sm {
    font-size: 12px;

}
