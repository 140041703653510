.attrInpts {
  margin: 5px;
}

.product {
  background: #d2fcdd;
  padding: 2px 10px;

}

.raw {
  background: #faf9d7;

}

.btnCtr {
  flex: 1;
  text-align: end;
}

.okbtn {
  background: #3f51b5;
  border: 0px;
  color: white;
}

.choosenCount {
  color: @blue-color;
}


.attWithUnit {
  flex-direction: row;
}

.unitSep {
  padding-left: 10px;
}

.inputNumber {
  padding: 3px;
  font-size: 12px
}

.dropInput {
  padding: 2px;
  font-size: 10px;
  height: 30px;
}
@primary-color: #48c75b;@blue-color: #3f51b5;