.filterCol {
  padding: 5px;
  height: 75px;
}

.noteArea {
  padding: 10px 5px;
}

.totalQty {
  text-align: end;
  flex: 1;
}

.vehicles {
  margin-left: 10px;
}

.btnctr {
  justify-content: center;
}

.btnCdtr {
  flex: 1;
  text-align: end;
  padding-top: 20px;
}

.choosenCount {
  color: @blue-color;
}


.addInp {
  margin-right: 5px;
  margin-bottom: 10px;
}

.autoCompleteEmail {
  font-size: 12px !important;
}

.addressInp {
  /*input{
    width:30px;
  }*/
  margin-top: 10px;

}

.autoCompleteAddress {
  font-size: 12px !important;
  color: gray
}

.addInput {
  min-width: 200px;
}

.addAddress {
  width: 500px;
}

.search {
  width: 200px;
}

.fromShow {
  color: #5CC75C;
}

.toShow {
  color: orange;
}

.filterSep {
  margin-right: 5px;
}

.attrFil {
  margin-top: 20px;
}

.attrInpts {
  margin-right: 2px;
}

.attUl {
  list-style: none;
  margin: 0px;
  padding: 0px;

  li {
    display: inline-block;
    margin-right: 5px;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.reportTable {

  width: 100%;
  text-transform: uppercase;
  margin-top: 1em;

  thead th, td:first-child {
    background-color: @primary-color;
    color: white;
    border: 1px white solid;
  }

  tr:nth-child(2n) {
    background-color: lighten(@primary-color, 45%);
  }

  td, th {
    padding: 10px;
    text-align: center;
    border: 1px lighten(@primary-color, 25%) solid;

  }
}

.vaAttUl {
  list-style: none;
  padding-left: 0px;
  margin: 0px;

  li {
    display: inline-block;
    margin-right: 5px;

  }
}

.cardStyle {
  padding: 5px 25px;
  background-color: white;

}

.unitSep {
  padding-left: 10px;
}

.qtyInp {
  width: 100px;
  margin-right: 5px;
  height: 25px;
}

.inputNumber {
  padding: 3px;
  font-size: 11px;
}

.overFlow {
  padding: 6px;
  color: red;
}

.classification {

  border: 1px solid #e5e5e5;
  padding: 10px;
  margin: 20px 10px;
  width: 80%;
  float: right;
}
@primary-color: #48c75b;@blue-color: #3f51b5;